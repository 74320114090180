/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Form, Select, Input, Button } from "antd";
import Big from "big.js";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import "./css/mywallet.css";
import {
  selectContractAddress,
  selectContractPayAbi,
  selectContractRPC,
  selectContractTokenAbi,
} from "../../store/getCid/getCidSlice";
import useConfirmTransaction from "../../hooks/useConfirmTransaction";
import {
  getAllowance,
  selectAllowance,
} from "../../store/getAllowance/getAllowanceSlice";
import useWeb3Contract from "../../hooks/useContract";

export default function ChargeAccountForm({
  assets,
  onCharge,
  setChargeSuccess,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { address } = useAccount();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const { tokenContract } = useWeb3Contract({ selectedAsset });
  const [IsCoin, setIsCoin] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const allowance = useSelector(selectAllowance);
  const [chargeLoading, setChargeLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const contractRPC = useSelector(selectContractRPC);
  const tokenAbi = useSelector(selectContractTokenAbi);
  const payAbi = useSelector(selectContractPayAbi);
  const contractAddress = useSelector(selectContractAddress);

  const allowanceFn = async () => {
    const tokenAsset =
      selectedAsset &&
      selectedAsset !== "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
    if (selectedAsset && tokenAsset && address && contractRPC) {
      try {
        dispatch(getAllowance({ tokenContract, address, contractAddress }));
        return true;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
    return null;
  };
  useEffect(() => {
    allowanceFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, selectedAsset, approved, contractRPC]);

  const charge = useConfirmTransaction({
    address: contractAddress,
    value: IsCoin ? selectedAmount : 0,
    abi: payAbi,
    functionName: "chargeAccount",
  });

  useEffect(() => {
    if (charge.status !== "idle" && charge.status !== "loading") {
      if (charge.receipt?.status) {
        toast.success("Deposit successful");
        setChargeSuccess(true);
        setSelectedAmount(null);
        setIsCoin(false);
        setTimeout(() => {
          setChargeSuccess(false);
        }, 10000);
      } else {
        toast.warn("Something went wrong, try later again!");
      }
      setChargeLoading(false);
      form.resetFields();
    }
  }, [charge.status]);

  useEffect(() => {
    if (
      charge?.error &&
      charge?.error.toString().includes("User rejected the request")
    )
      setChargeLoading(false);
  }, [charge?.error]);

  const handleSubmit = async (values) => {
    setChargeLoading(true);
    await charge.handleTransaction(
      [selectedAsset, address, selectedAmount],
      address
    );
    onCharge(values);
  };

  const handleAssetChange = (value) => {
    if (value === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") {
      setIsCoin(true);
    } else {
      setIsCoin(false);
    }
    setSelectedAsset(value);
  };

  const handleAmountChange = (event) => {
    const amount = event.target.value;
    const numericValue = Number(amount);
    const bigValue = new Big(numericValue).times("1e18");
    const valueInBig = bigValue.toFixed();
    setSelectedAmount(valueInBig);
  };

  // approve process
  const approve = useConfirmTransaction({
    address: selectedAsset,
    abi: tokenAbi,
    functionName: "approve",
  });

  useEffect(() => {
    if (approve.status !== "idle" && approve.status !== "loading") {
      if (approve.receipt?.status) {
        setApproved(true);
      } else {
        toast.warn("Something went wrong, try later again!");
      }
      setApproveLoading(false);
    }
  }, [approve.status]);

  useEffect(() => {
    if (approve?.error) setApproveLoading(false);
  }, [approve?.error]);

  const handleApprove = async () => {
    setApproveLoading(true);
    await approve.handleTransaction(
      [
        contractAddress,
        115792089237316195423570985008687907853269984665640564039457584007913129639935n,
      ],
      address
    );
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl tracking-wide text-white opacity-[90%] font-semibold mb-4">
        Charge Account
      </h2>
      <Form form={form} onFinish={handleSubmit} layout="inline">
        <Form.Item name="asset" label="Select Asset">
          <Select
            popupMatchSelectWidth={false}
            style={{ width: "110px" }}
            onChange={handleAssetChange}
          >
            {assets.map((asset) => (
              <Select.Option key={asset.id} value={asset.id}>
                {asset.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="amount" label="Amount">
          <Input type="number" className="w-16" onChange={handleAmountChange} />
        </Form.Item>
        <Form.Item>
          <Button
            loading={chargeLoading}
            disabled={
              !IsCoin
                ? !selectedAmount ||
                  selectedAmount === "0" ||
                  !selectedAsset ||
                  (!approved &&
                    new Big(Number(selectedAmount)).gt(
                      new Big(Number(allowance))
                    ))
                : !selectedAmount || selectedAmount === "0" || !selectedAsset
            }
            type="primary"
            htmlType="submit"
            className="bg-blue-400 hover:bg-blue-800 text-white transition duration-300"
            style={{ color: "#fff" }}
          >
            Charge
          </Button>
        </Form.Item>
        {!IsCoin &&
          !(
            !selectedAmount ||
            selectedAmount === "0" ||
            !selectedAsset ||
            approved ||
            new Big(Number(selectedAmount)).lt(new Big(Number(allowance)))
          ) && (
            <Form.Item>
              <Button
                onClick={handleApprove}
                loading={approveLoading}
                type="primary"
                className="bg-blue-400 hover:bg-blue-800 text-white transition duration-300"
                style={{ color: "#fff" }}
              >
                Approve
              </Button>
            </Form.Item>
          )}
      </Form>
    </div>
  );
}
