import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { create } from "@web3-storage/w3up-client";
import { useAccount } from "wagmi";
import InputCom from "../Helpers/Inputs/InputCom";
import {
  selectUserProfileAbi,
  selectUserProfileAddress,
} from "../../store/getCid/getCidSlice";
import useConfirmTransaction from "../../hooks/useConfirmTransaction";
import {
  SelectIsBanned,
  getUserProfileAsync,
  SelectUserInfo,
} from "../../store/getUserProfile/getUserProfileSlice";
import useWeb3Contract from "../../hooks/useContract";

const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/images/profile-info-profile.png`;

export default function PersonalInfoTab({
  profileImgPreview,
  profileImg,
  profileImgInput,
  browseProfileImg,
  profileImgChangHandler,
  hasNewProfileImg,
}) {
  const dispatch = useDispatch();
  const userProfileAbi = useSelector(selectUserProfileAbi);
  const userProfileAddress = useSelector(selectUserProfileAddress);
  const isBanned = useSelector(SelectIsBanned);
  const userInfo = useSelector(SelectUserInfo);
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const { userProfileContract } = useWeb3Contract({});

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    link: "",
    bio: "",
    profileImgUrl: "",
  });

  useEffect(() => {
    if (userInfo) {
      setFormData({
        firstName: userInfo.firstName || "",
        lastName: userInfo.lastName || "",
        businessName: userInfo.businessName || "",
        email: userInfo.email || "",
        link: userInfo.link || "",
        bio: userInfo.bio || "",
        profileImgUrl: userInfo.profileImgUrl || "",
      });
    }
  }, [userInfo]);

  const inputHandler = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // update user profile start
  const updateProfile = useConfirmTransaction({
    address: userProfileAddress,
    abi: userProfileAbi,
    functionName: "updateProfile",
  });

  useEffect(() => {
    if (updateProfile.status !== "idle" && updateProfile.status !== "loading") {
      if (updateProfile.receipt?.status) {
        dispatch(getUserProfileAsync({ address, userProfileContract })).then(
          (action) => {
            if (action.payload.response.cid) {
              toast.success("Your profile has been updated");
              setLoading(false);
            }
          }
        );
      } else {
        toast.warn("Something went wrong, try later again!");
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfile.receipt, updateProfile.status]);

  useEffect(() => {
    if (
      updateProfile?.error &&
      updateProfile?.error.toString().includes("User rejected the request")
    )
      setLoading(false);
  }, [updateProfile?.error]);
  // update user profile end

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const client = await create();
      await client.login("lqrlab@laqira.io").then(() => {
        console.log("logged in to lqrlab@laqira.io ");
      });

      await client
        .setCurrentSpace(
          "did:key:z6MkoSqmw1XQVr8QjWGwb8uQ8iPfTtQT4gCThNNyfZsvyDxo"
        )
        .then(() => {
          console.log("the space allocated: ", client);
        });
      const imgFile = [new File([profileImg], "profile.png")];
      const profileImgCid = await client.uploadDirectory(imgFile);
      console.log("profileImgCid created: ", profileImgCid);
      const imgUrl = hasNewProfileImg
        ? `https:${profileImgCid}.ipfs.w3s.link/profile.png`
        : formData.profileImgUrl;
      const userData = [
        new File(
          [JSON.stringify({ ...formData, profileImgUrl: imgUrl })],
          "userInfo.json"
        ),
      ];
      const userInfoCid = await client.uploadDirectory(userData);
      console.log("userInfoCid created: ", userInfoCid);
      const userInfoUrl = `https:${userInfoCid}.ipfs.w3s.link/userInfo.json`;
      console.log("is being requested to create a transaction: ", userInfoUrl);
      await updateProfile.handleTransaction([userInfoUrl], address);
    } catch (err) {
      console.log(err);
    }
  };

  // get user profile
  useEffect(() => {
    if (address)
      dispatch(getUserProfileAsync({ address, userProfileContract }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return (
    <Spin
      spinning={loading}
      tip="Updating your profile... 
      confirm the transaction in your wallet when prompted."
    >
      <div className="personal-info-tab w-full flex flex-col justify-between">
        <div className="flex flex-col-reverse sm:flex-row">
          <div className="flex-1 sm:mr-10">
            <div className="fields w-full">
              {/* first name and last name */}
              <div className="xl:flex xl:space-x-7 mb-6">
                <div className="field w-full mb-6 xl:mb-0">
                  <InputCom
                    label="First Name"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    inputHandler={(e) => inputHandler(e)}
                  />
                </div>
                <div className="field w-full">
                  <InputCom
                    label="Last Name"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    inputHandler={(e) => inputHandler(e)}
                  />
                </div>
              </div>
              {/* User Name */}
              <div className="field w-full mb-6">
                <InputCom
                  label="business Name"
                  type="text"
                  name="businessName"
                  value={formData.businessName}
                  inputHandler={(e) => inputHandler(e)}
                />
              </div>
              {/* External Links */}
              <div className="field w-full mb-6">
                <InputCom
                  label="External Links"
                  type="text"
                  name="link"
                  value={formData.link}
                  inputHandler={(e) => inputHandler(e)}
                />
              </div>
              {/* bio */}
              <div className="field w-full mb-6">
                <InputCom
                  label="Bio"
                  type="bio"
                  name="bio"
                  value={formData.bio}
                  inputHandler={(e) => inputHandler(e)}
                  rows={7}
                />
              </div>
              {/* email */}
              <div className="field w-full mb-6">
                <InputCom
                  label="Email"
                  type="email"
                  name="email"
                  value={formData.email}
                  inputHandler={(e) => inputHandler(e)}
                />
              </div>
            </div>
          </div>
          <div className="w-[232px] mb-10">
            <div className="update-profile w-full mb-9">
              <div className="flex justify-center">
                <div className="w-full relative">
                  {
                    // Show new uploaded preview
                    hasNewProfileImg && profileImgPreview ? (
                      <img
                        src={profileImgPreview}
                        alt=""
                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover"
                      />
                    ) : // Otherwise show user info
                    !hasNewProfileImg && formData?.profileImgUrl ? (
                      <img
                        src={formData?.profileImgUrl}
                        alt=""
                        className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover"
                      />
                    ) : (
                      // Default if no image
                      !hasNewProfileImg &&
                      !formData?.profileImgUrl && (
                        <img
                          src={defaultProfileImage}
                          alt=""
                          className="sm:w-[198px] sm:h-[198px] w-[120px] h-[120px] rounded-full overflow-hidden object-cover"
                        />
                      )
                    )
                  }
                  <input
                    ref={profileImgInput}
                    onChange={(e) => profileImgChangHandler(e)}
                    type="file"
                    className="hidden"
                    accept="image/*"
                  />
                  <div
                    onClick={browseProfileImg}
                    className="w-[32px] h-[32px] absolute bottom-7 sm:right-10 right-[105px]  hover:bg-pink bg-dark-gray rounded-full cursor-pointer"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5147 11.5C17.7284 12.7137 18.9234 13.9087 20.1296 15.115C19.9798 15.2611 19.8187 15.4109 19.6651 15.5683C17.4699 17.7635 15.271 19.9587 13.0758 22.1539C12.9334 22.2962 12.7948 22.4386 12.6524 22.5735C12.6187 22.6034 12.5663 22.6296 12.5213 22.6296C11.3788 22.6334 10.2362 22.6297 9.09365 22.6334C9.01498 22.6334 9 22.6034 9 22.536C9 21.4009 9 20.2621 9.00375 19.1271C9.00375 19.0746 9.02997 19.0109 9.06368 18.9772C10.4123 17.6249 11.7609 16.2763 13.1095 14.9277C14.2295 13.8076 15.3459 12.6913 16.466 11.5712C16.4884 11.5487 16.4997 11.5187 16.5147 11.5Z"
                        fill="white"
                      />
                      <path
                        d="M20.9499 14.2904C19.7436 13.0842 18.5449 11.8854 17.3499 10.6904C17.5634 10.4694 17.7844 10.2446 18.0054 10.0199C18.2639 9.76139 18.5261 9.50291 18.7884 9.24443C19.118 8.91852 19.5713 8.91852 19.8972 9.24443C20.7251 10.0611 21.5492 10.8815 22.3771 11.6981C22.6993 12.0165 22.7105 12.4698 22.3996 12.792C21.9238 13.2865 21.4443 13.7772 20.9686 14.2717C20.9648 14.2792 20.9536 14.2867 20.9499 14.2904Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-footer w-full">
          <div className="w-full h-[120px] border-t border-light-purple flex justify-end items-center">
            <div className="flex space-x-4 mr-9">
              <button
                type="button"
                className="w-[152px] h-[46px] flex justify-center items-center btn-gradient text-base rounded-full text-white"
                onClick={handleSubmit}
                disabled={isBanned}
              >
                Update Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
