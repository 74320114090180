import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// loading: 'idle' | 'pending' | 'succeeded' | 'failed'
const initialState = {
  isRegistered: false,
  loading: "idle",
  error: false,
};

export const getRegistryInfoAsync = createAsyncThunk(
  "contract/isRegistered",
  async ({ address, payContract }) => {
    try {
      const response = await payContract.methods.isRegistered(address).call();
      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
);

const changeRegistryInfo = (state, action) => {
  state.isRegistered = action.payload;
};

const getRegistryInfoSlice = createSlice({
  name: "getRegistryInfo",
  initialState,
  reducers: {
    changeRegistryInfo,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRegistryInfoAsync.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getRegistryInfoAsync.fulfilled, (state, action) => {
        state.isRegistered = action.payload;
        state.loading = "succeeded";
        state.error = false;
      })
      .addCase(getRegistryInfoAsync.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

export const SelectIsRegistered = (state) =>
  state?.getRegistryInfo?.isRegistered;
export const SelectLoading = (state) => state?.getRegistryInfo?.loading;

export const { changeRegistryInfo: changeRegistryInfoAction } =
  getRegistryInfoSlice.actions;

export default getRegistryInfoSlice.reducer;
