import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// loading: 'idle' | 'pending' | 'succeeded' | 'failed'
const initialState = {
  assets: [],
  loading: "idle",
  error: false,
};
export const getAllowedAssets = createAsyncThunk(
  "contract/allowedAssets",
  async ({ payContract }) => {
    const response = await payContract.methods.getAllowedAssets().call();
    return response;
  }
);

const getAllowedAssetsSlice = createSlice({
  name: "allowedAssets",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllowedAssets.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getAllowedAssets.fulfilled, (state, action) => {
        state.assets = action.payload;
        state.loading = "succeeded";
        state.error = false;
      })
      .addCase(getAllowedAssets.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

// export const { handleAssetsInfoList } = getAllowedAssetsSlice.actions;
export const selectAssets = (state) => state?.getAllowedAssets?.assets;
export default getAllowedAssetsSlice.reducer;
