import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// loading: 'idle' | 'pending' | 'succeeded' | 'failed'
const initialState = {
  assetInfo: null,
  loading: "idle",
  error: false,
};

export const getFeeAssetInfo = createAsyncThunk(
  "contract/feeAssetInfo",
  async ({ feeAssetAddress, payContract }) => {
    try {
      const response = await payContract.methods
        .feeAssetInfo(feeAssetAddress)
        .call();
      if (response.status === true) {
        const { amount } = response;
        return amount.toString();
      }
      return null;
    } catch (err) {
      throw new Error("Error happened while fetching the asset amount!");
    }
  }
);

const getFeeAssetInfoSlice = createSlice({
  name: "getFeeAssetInfo",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFeeAssetInfo.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getFeeAssetInfo.fulfilled, (state, action) => {
        state.assetInfo = action.payload;
        state.loading = "succeeded";
        state.error = false;
      })
      .addCase(getFeeAssetInfo.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

export default getFeeAssetInfoSlice.reducer;
