import { configureStore } from "@reduxjs/toolkit";
import drawerReducer from "./drawer";
import getAllowanceReducer from "./getAllowance/getAllowanceSlice";
import getFeeAssetInfoReducer from "./getFeeAssetInfo/getFeeAssetInfoSlice";
import getRegistryInfoReducer from "./getRegistryInfo/getRegistryInfoSlice";
import getInAppBalanceReducer from "./getInAppBalance/getInAppBalanceSlice";
import getFeeAssetsReducer from "./getFeeAssets/getFeeAssetsSlice";
import getProviderRegistryReducer from "./getProviderRegistry/getProviderRegistry";
import walletReducer from "./wallet/walletSlice";
import getLqrPriceReducer from "./getLqrPrice/getLqrPriceSlice";
import getAllowedAssetsReducer from "./getAllowedAssets/getAllowedAssetsSlice";
import getDircetPayReducer from "./getDircetPay/getDircetPaySlice";
import getCidReducer from "./getCid/getCidSlice";
import getUserProfileReducer from "./getUserProfile/getUserProfileSlice";

export default configureStore({
  reducer: {
    drawer: drawerReducer,
    getAllowance: getAllowanceReducer,
    getAllowedAssets: getAllowedAssetsReducer,
    getFeeAssetInfo: getFeeAssetInfoReducer,
    getRegistryInfo: getRegistryInfoReducer,
    getInAppBalance: getInAppBalanceReducer,
    getFeeAssets: getFeeAssetsReducer,
    getProviderRegistry: getProviderRegistryReducer,
    wallet: walletReducer,
    getLqrPrice: getLqrPriceReducer,
    getDirectPay: getDircetPayReducer,
    getCid: getCidReducer,
    getUserProfile: getUserProfileReducer,
  },
});
