import { useContractWrite, useWaitForTransaction } from "wagmi";

function useConfirmTransaction({ address, abi, functionName, value }) {
  const { writeAsync, data, error } = useContractWrite({
    address,
    value: value || 0,
    abi,
    functionName,
  });

  const { status, data: receipt } = useWaitForTransaction({
    hash: data?.hash,
  });

  const handleTransaction = async (args, from) => {
    try {
      await writeAsync({
        from,
        args,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return {
    handleTransaction,
    tx: data,
    receipt,
    status,
    error,
  };
}

export default useConfirmTransaction;
