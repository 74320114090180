import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawer: true,
};

export const counterSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    drawerToggle: (state) => {
      state.drawer = !state.drawer;
    },
  },
});

export const { drawerToggle } = counterSlice.actions;
export const SelectDrawer = (state) => state?.drawer?.drawer;

export default counterSlice.reducer;
