import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Action Type Constants
const GET_DIRECT_PAY = "contract/getDirectPay";

// Initial State
const initialState = {
  directPay: false,
  loading: "idle",
  error: false,
};
// Async Thunks
export const getDirectPay = createAsyncThunk(
  GET_DIRECT_PAY,
  async ({ address, payContract }) => {
    try {
      const res = await payContract.methods.getDirectPay(address).call();
      return res;
    } catch (e) {
      console.log("error while getting direct recive status: ", e);
      return null;
    }
  }
);

const changeDirectPay = (state, action) => {
  state.directPay = action.payload;
};

// Slice
const directPaySlice = createSlice({
  name: "directPay",
  initialState,
  reducers: {
    changeDirectPay,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDirectPay.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getDirectPay.fulfilled, (state, action) => {
        state.directPay = action.payload;
        state.loading = "succeeded";
      })
      .addCase(getDirectPay.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

export const { changeDirectPay: changeDirectPayAction } =
  directPaySlice.actions;

export default directPaySlice.reducer;
