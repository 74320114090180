import { useEffect } from "react";
import { Spin } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useNetwork } from "wagmi";
import Error404 from "./components/Error404";
import RegisterPage from "./views/RegisterPage";
import AuthProfilePage from "./views/AuthProfilePage";
import HomePages from "./views/HomePages";
import MyWalletPage from "./views/MyWalletPage";
import {
  SelectIsRegistered,
  SelectLoading,
  getRegistryInfoAsync,
} from "./store/getRegistryInfo/getRegistryInfoSlice";
import { getCidAsync, getCidWcpiAsync } from "./store/getCid/getCidSlice";
import useWeb3Contract from "./hooks/useContract";
import LaqiraPayPage from "./views/LaqiraPayPage";

export default function Routers() {
  const dispatch = useDispatch();
  const loading = useSelector(SelectLoading);
  const cidLoading = useSelector((s) => s.getCid?.loading);
  const { payContract } = useWeb3Contract({});
  const { chain } = useNetwork();
  const { address } = useAccount();
  const navigate = useNavigate();

  useEffect(() => {
    if (chain && !chain.unsupported) {
      const chainId = chain.id;
      dispatch(getCidAsync(chainId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain]);

  useEffect(() => {
    dispatch(getCidWcpiAsync());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (address && cidLoading === "succeeded") {
      dispatch(getRegistryInfoAsync({ address, payContract }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, cidLoading]);

  const isRegistered = useSelector(SelectIsRegistered);

  useEffect(() => {
    if (!address || !isRegistered) {
      navigate("./");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isRegistered]);

  return (
    <Routes>
      {!!address && !!isRegistered && (
        <Route path="/profile" element={<AuthProfilePage />} />
      )}
      <Route exact path="/" element={<HomePages />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/my-wallet" element={<MyWalletPage />} />
      <Route path="/laqira-pay" element={<LaqiraPayPage />} />
      <Route
        path="*"
        element={
          loading === "idle" || loading === "pending" ? (
            <div className="flex justify-center items-center w-full h-screen bg-[#232247]">
              <div className="flex justify-center">
                <Spin size="large" spinning />
              </div>
            </div>
          ) : (
            <Error404 />
          )
        }
      />
    </Routes>
  );
}
