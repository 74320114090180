import Web3 from "web3";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as abi from "../../abi/lqrAbi";

// loading: 'idle' | 'pending' | 'succeeded' | 'failed'
const initialState = {
  price: "",
  loading: "idle",
  error: false,
};

const getBalanceOf = async (metaID, contractAddress) => {
  const provider = "https://bsc-dataseed.binance.org/";
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(abi, contractAddress);
  const balance = await contract.methods.balanceOf(metaID).call();

  const balanceString = balance.toString();

  const roundedBalance = parseFloat((Number(balanceString) / 1e18).toFixed(6));

  return roundedBalance;
};

export const getLqrPrice = createAsyncThunk("getLqrPrice", async () => {
  const mainContract1 = "0xd14bc290576B51f24f3b4A99F5410D3221EBDcEF";
  const mainContract2 = "0x1B96B92314C44b159149f7E0303511fB2Fc4774f";
  const subContract11 = "0xbc81ea817b579eC0334BcA8E65E436b7cB540147";
  const subContract12 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  const subContract21 = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  const subContract22 = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";

  const LQR_1 = await getBalanceOf(mainContract1, subContract11);
  const WBNB_1 = await getBalanceOf(mainContract1, subContract12);
  const WBNB_2 = await getBalanceOf(mainContract2, subContract21);
  const BUSD_2 = await getBalanceOf(mainContract2, subContract22);

  const price = parseFloat(
    (parseFloat(WBNB_1 / LQR_1) * parseFloat(BUSD_2 / WBNB_2)).toFixed(6)
  );

  return price;
});

const getLqrPriceSlice = createSlice({
  name: "getLqrPrice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getLqrPrice.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getLqrPrice.fulfilled, (state, action) => {
        state.price = action.payload;
        state.loading = "succeeded";
        state.error = false;
      })
      .addCase(getLqrPrice.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

export default getLqrPriceSlice.reducer;
