import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// loading: 'idle' | 'pending' | 'succeeded' | 'failed'
const initialState = {
  amount: "",
  loading: "idle",
  error: false,
};

export const getAllowance = createAsyncThunk(
  "contract/getAllowance",
  async ({ tokenContract, address, contractAddress }) => {
    const response = await tokenContract.methods
      .allowance(address, contractAddress)
      .call();
    return response.toString();
  }
);

const changeAsset = (state, action) => {
  state.currentAsset = action.payload;
};

const getAllowanceSlice = createSlice({
  name: "getAllowance",
  initialState,
  reducers: {
    changeAsset,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllowance.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getAllowance.fulfilled, (state, action) => {
        state.amount = action.payload;
        state.loading = "succeeded";
        state.error = false;
      })
      .addCase(getAllowance.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

export const selectAllowance = (state) => state?.getAllowance?.amount;

export const { changeAsset: changeAssetAction } = getAllowanceSlice.actions;

export default getAllowanceSlice.reducer;
