/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Big from "big.js";
import Layout from "../components/Partials/Layout";
import { getFeeAssets } from "../store/getFeeAssets/getFeeAssetsSlice";
import { getFeeAssetInfo } from "../store/getFeeAssetInfo/getFeeAssetInfoSlice";
import Token from "../components/Register/Token";
import { SelectIsRegistered } from "../store/getRegistryInfo/getRegistryInfoSlice";
import {
  selectContractAddress,
  selectContractPayAbi,
} from "../store/getCid/getCidSlice";
import useConfirmTransaction from "../hooks/useConfirmTransaction";
import useWeb3Contract from "../hooks/useContract";

export default function RegisterPage() {
  const dispatch = useDispatch();
  const { feeAssets, loading } = useSelector((state) => state.getFeeAssets);
  const isRegistered = useSelector(SelectIsRegistered);
  const { address } = useAccount();
  const { payContract } = useWeb3Contract({});
  const [registryForm] = Form.useForm();
  const [asset, setAsset] = useState("");
  const [amount, setAmount] = useState("");
  const [showAmount, setShowAmount] = useState("");
  const [IsToken, setIsToken] = useState(false);
  const payAbi = useSelector(selectContractPayAbi);
  const contractAddress = useSelector(selectContractAddress);

  useEffect(() => {
    // Fetch allowed assets when the component mounts
    dispatch(getFeeAssets({ payContract }));
  }, [dispatch, address]);

  const handleAssetChange = (value) => {
    setAsset(value);
    dispatch(getFeeAssetInfo({ feeAssetAddress: value, payContract })).then(
      (action) => {
        if (action.payload !== undefined) {
          setAmount(action.payload);
          const amountInBig = new Big(action.payload);
          const convertedAmount = amountInBig.div(10e18);
          setShowAmount(convertedAmount.toString());
        }
      }
    );
    if (value !== "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") {
      setIsToken(true);
    } else {
      setIsToken(false);
    }
  };

  // Register handler
  const register = useConfirmTransaction({
    address: contractAddress,
    abi: payAbi,
    functionName: "providerRegister",
    value: amount,
  });

  useEffect(() => {
    if (register.status !== "idle" && register.status !== "loading") {
      if (register.receipt?.status) {
        toast.success("Registered successfully");
      } else {
        toast.warn("Something went wrong, try later again!");
      }
    }
  }, [register.status]);

  const handleSubmitForm = async (data) => {
    await register.handleTransaction([data.asset], address);
  };

  return (
    <Layout>
      <div className="relative">
        {isRegistered && (
          <p className="text-fuchsia-800 font-bold text-center mb-4">
            You already registered.
          </p>
        )}
        <Form
          form={registryForm}
          onFinish={handleSubmitForm}
          layout="vertical"
          className="bg-white px-8 pt-6 pb-8 mb-4 rounded-lg shadow-md md:w-1/2 lg:w-1/3 mx-auto"
        >
          <Form.Item
            label="Select asset"
            name="asset"
            className="font-bold mb-4"
          >
            <Select
              loading={loading === "pending"}
              onChange={handleAssetChange}
              placeholder="Select Asset"
              className="w-full"
              options={feeAssets.map((fee) => ({
                label: `${fee}`,
                value: fee,
              }))}
            />
          </Form.Item>
          <div className="mb-[30px] font-bold">Fee Assets : {showAmount}</div>
          <Form.Item>
            {IsToken ? (
              <Token
                asset={asset}
                amount={amount}
                isRegistered={isRegistered}
              />
            ) : (
              <Button
                loading={register.isLoading}
                disabled={!address || isRegistered}
                type="primary"
                htmlType="submit"
                className="w-full bg-blue-500 text-white hover:bg-current"
              >
                Register
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
}
