import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserProfileInfo } from "../../apis/CidApi";

// loading: 'idle' | 'pending' | 'succeeded' | 'failed'
const initialState = {
  userInfo: null,
  isBanned: false,
  banReason: null,
  loading: "idle",
  error: false,
};
export const getUserProfileAsync = createAsyncThunk(
  "profile",
  async ({ address, userProfileContract }) => {
    try {
      const response = await userProfileContract.methods
        .getUserProfile(address)
        .call();
      const userCidInfo = await getUserProfileInfo(response.cid);

      return { response, userCidInfo };
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

const getUserProfileSlice = createSlice({
  name: "getUserProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileAsync.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getUserProfileAsync.fulfilled, (state, action) => {
        state.userInfo = action.payload.userCidInfo;
        state.isBanned = action.payload.response.isBanned;
        state.banReason = action.payload.response.banReason;
        state.loading = "succeeded";
        state.error = false;
      })
      .addCase(getUserProfileAsync.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

export const SelectLoading = (state) => state?.getUserProfile?.loading;
export const SelectIsBanned = (state) => state?.getUserProfile?.isBanned;
export const SelectUserInfo = (state) => state?.getUserProfile?.userInfo;
export const SelectBanReason = (state) => state?.getUserProfile?.banReason;

export default getUserProfileSlice.reducer;
