import { ReactComponent as DoubleArrowsIcon } from "../../../icons/double-arrows.svg";
import useToggle from "../../../hooks/useToggle";
import SearchCom from "../../Helpers/SearchCom";
import ProviderRegistry from "./ProviderRegistry";
import UserBalance from "./UserBalance";

export default function Header({ sidebarHandler }) {
  const [balanceDropdown, setbalanceValue] = useToggle(false);
  const [userProfileDropdown, setProfileDropdown] = useToggle(false);
  const clickAwayhandler = () => {
    if (balanceDropdown) {
      setbalanceValue.toggle();
    }
    if (userProfileDropdown) {
      setProfileDropdown.toggle();
    }
  };

  return (
    <>
      <div className="header-wrapper backdrop-blur-sm w-full h-full flex items-center xl:px-0 md:px-10 px-5">
        <div className="flex justify-between items-center w-full">
          <button
            className="xl:hidden block mr-10"
            type="button"
            onClick={sidebarHandler}
          >
            <DoubleArrowsIcon />
          </button>

          <div className="search-bar lg:block hidden w-[376px]">
            <SearchCom />
          </div>

          <div className="user-info flex items-center justify-between xs:justify-end w-full lg:space-x-7 space-x-2 z-10 ">
            <ProviderRegistry />
            <UserBalance
              setbalanceValue={setbalanceValue}
              balanceDropdown={balanceDropdown}
            />
          </div>
        </div>
      </div>
      {balanceDropdown || userProfileDropdown ? (
        <div
          onClick={clickAwayhandler}
          className="w-full h-screen fixed left-0"
          style={{ zIndex: "-1" }}
        ></div>
      ) : (
        ""
      )}
    </>
  );
}
