import { useNavigate } from "react-router-dom";

export default function Error404() {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center w-full h-screen bg-[#232247]">
      <div className="flex justify-center">
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="btn-gradient text-white text-lg w-[150px] h-[50px] rounded-full"
        >
          Go Back
        </button>
      </div>
    </div>
  );
}
