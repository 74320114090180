import { Provider } from "react-redux";
import Routers from "./Routers";
import Toaster from "./components/Helpers/Toaster";
import store from "./store/store";
import RainbowProvider from "./providers/RainbowProvider";
import "./index.css";

function App() {
  return (
    <Provider store={store}>
      <RainbowProvider>
        <Routers />
        <Toaster />
      </RainbowProvider>
    </Provider>
  );
}

export default App;
