import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: "",
  chainId: 0,
};

const wallet = (state, action) => {
  state.address = action.payload;
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    wallet,
  },
});

export const { wallet: walletAction } = walletSlice.actions;

export default walletSlice.reducer;
