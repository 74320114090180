import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as LogoutIcon } from "../../icons/logout2.svg";
import { ReactComponent as QuestionIcon } from "../../icons/question.svg";
import useToggle from "../../hooks/useToggle";
import { drawerToggle } from "../../store/drawer";
import ModalCom from "../Helpers/ModalCom";
import Header from "./Header/Header";
import MobileSidebar from "./MobileSideBar";
import Sidebar from "./Sidebar";

export default function Layout({ children }) {
  const { drawer } = useSelector((state) => state.drawer);
  const dispatch = useDispatch();
  const [MobileSideBar, setMobileSidebar] = useToggle(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const logoutModalHandler = () => {
    setLogoutModal(!logoutModal);
  };
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("email");
    localStorage.clear();
    toast.success("Come Back Soon", {
      icon: `🙂`,
    });

    navigate("/login", { replace: true });
  };
  return (
    <>
      <div className="nft-main-wrapper-layout">
        <div className="nft-wrapper-layout-container 2xl:pr-20 md:pr-10 pr-2 pl-2 md:pl-0 w-full min-h-screen flex">
          {/* sidebar */}
          <div
            className={`nft-sidebar xl:block hidden section-shadow transition-all duration-500 ease-in-out cubic-bezier(0.52, 0.35, 0.7, 0.37) ${
              drawer
                ? "2xl:w-[335px] w-[280px] 2xl:pl-20 pl-10 pr-6 "
                : "w-[70px]"
            } bg-white h-full overflow-y-scroll overflow-style-none fixed left-0 top-0 pt-8`}
          >
            <Sidebar
              logoutModalHandler={logoutModalHandler}
              sidebar={drawer}
              action={() => dispatch(drawerToggle())}
            />
          </div>
          {MobileSideBar && (
            <div
              onClick={() => setMobileSidebar.toggle()}
              className="bg-black bg-opacity-20 fixed left-0 top-0 w-full h-full z-[50] block xl:hidden"
            ></div>
          )}
          <div
            className={`nft-sidebar block xl:hidden section-shadow w-[280px] pl-3 bg-white h-full overflow-y-scroll overflow-style-none fixed z-[60]  top-0 pt-8 transition-all duration-500 ease-in-out cubic-bezier(0.52, 0.35, 0.7, 0.37) ${
              MobileSideBar ? "left-0" : "-left-[290px]"
            }`}
          >
            <MobileSidebar
              logoutModalHandler={logoutModalHandler}
              sidebar={MobileSideBar}
              action={() => setMobileSidebar.toggle()}
            />
          </div>
          {/* end sidebar */}
          <div
            className={`nft-header-container-wrapper flex-1 md:ml-10 ${
              drawer ? "2xl:ml-[375px] xl:ml-[310px]" : "xl:ml-[110px]"
            }  h-full`}
          >
            {/* header */}
            <div className="nft-header w-full lg:h-[100px] h-[70px] default-border-bottom z-40 xl:sticky fixed top-0 left-0 ">
              <Header
                sidebarHandler={() => setMobileSidebar.toggle()}
                logoutModalHandler={logoutModalHandler}
              />
            </div>
            {/* container */}
            <div className="nft-container 2xl:flex 2xl:space-x-8 h-full mb-12 lg:mt-[140px] mt-24 xl:mt-10">
              <div className="nft-main-container flex-1">
                {children && children}
              </div>
            </div>
          </div>
        </div>
      </div>
      {logoutModal && (
        <ModalCom action={logoutModalHandler} situation={logoutModal}>
          <div className="logout-modal-wrapper lg:w-[460px] h-full lg:h-auto bg-white lg:rounded-2xl">
            <div className="logout-modal-header w-full flex items-center justify-between lg:px-10 lg:py-8 px-[30px] py-[23px] border-b border-light-purple">
              <h1 className="text-26 font-bold text-dark-gray tracking-wide">
                Confirm
              </h1>
              <button type="button" className="" onClick={logoutModalHandler}>
                <LogoutIcon />
              </button>
            </div>
            <div className="logout-modal-body w-full flex flex-col items-center px-10 py-8">
              <div className="what-icon mb-6 cursor-pointer">
                <QuestionIcon />
              </div>
              <div className="mb-6">
                <p className="text-2xl tracking-wide text-center">
                  Are you sure you want to Logout NETMAX admin Panel?
                </p>
              </div>
              <div className="flex space-x-2.5">
                <button
                  onClick={logOut}
                  type="button"
                  className="text-white primary-gradient text-18 tracking-wide px-4 py-3 rounded-full"
                >
                  Yes Logout
                </button>
                <button
                  onClick={logoutModalHandler}
                  type="button"
                  className=" border-gradient text-18 tracking-wide px-4 py-3 rounded-full"
                >
                  <span className="text-gradient">Not Now</span>
                </button>
              </div>
            </div>
          </div>
        </ModalCom>
      )}
    </>
  );
}
