import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "@rainbow-me/rainbowkit/styles.css";
import {
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import {
  injectedWallet,
  metaMaskWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc, bscTestnet, arbitrum, polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import CustomAvatar from "../components/Helpers/Rainbow/CustomAvatar";
import { selectWcpi } from "../store/getCid/getCidSlice";

export default function RainbowProvider({ children }) {
  const [projectId, setProjectId] = useState("");
  const wcpi = useSelector(selectWcpi);

  useEffect(() => {
    if (wcpi) {
      setProjectId(wcpi);
    }
  }, [wcpi]);

  const { chains, publicClient } = configureChains(
    [bsc, bscTestnet, arbitrum, polygon],
    [publicProvider()]
  );
  const connectors = projectId
    ? connectorsForWallets([
        {
          groupName: "Recommended",
          wallets: [
            injectedWallet({ chains }),
            metaMaskWallet({ projectId, chains }),
            walletConnectWallet({ chains, projectId }),
          ],
        },
      ])
    : [];

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        avatar={CustomAvatar}
        modalSize="compact"
        coolMode
      >
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
