/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import { useAccount } from "wagmi";
import { Spin } from "antd";
import BlockchainAssetsList from "../components/MyWallet/AssetList";
import ChargeAccountForm from "../components/MyWallet/ChargeAccount";
import WithdrawAssetForm from "../components/MyWallet/WithDrawAsset";
import Layout from "../components/Partials/Layout";
import {
  getAllowedAssets,
  selectAssets,
} from "../store/getAllowedAssets/getAllowedAssetsSlice";
import {
  getInAppBalanceAsync,
  selectAssetInfo,
  selectLoading,
} from "../store/getInAppBalance/getInAppBalanceSlice";
import {
  selectCidJson,
  selectContractPayAbi,
} from "../store/getCid/getCidSlice";
import useWeb3Contract from "../hooks/useContract";

const background = `${process.env.PUBLIC_URL}/assets/images/shape/balance-bg.svg`;

export default function MyWalletPage() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const allowedAssetsList = useSelector(selectAssets);
  const allowedAssetInfoList = useSelector(selectAssetInfo);
  const cidJson = useSelector(selectCidJson);
  const contractPayAbi = useSelector(selectContractPayAbi);
  const [assets, setAssets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [chargeSuccess, setChargeSuccess] = useState(false);
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);
  const { address } = useAccount();
  const { payContract } = useWeb3Contract({});

  useEffect(() => {
    if (contractPayAbi) dispatch(getAllowedAssets({ payContract }));
  }, [contractPayAbi]);

  useEffect(() => {
    if (allowedAssetsList.length > 0 && address && contractPayAbi) {
      allowedAssetsList.forEach((asset) => {
        const params = { userAddress: address, assetAddress: asset };
        dispatch(getInAppBalanceAsync({ params, payContract }));
      });
    }
  }, [
    address,
    allowedAssetsList,
    chargeSuccess,
    withdrawSuccess,
    contractPayAbi,
  ]);

  useEffect(() => {
    // Update assets when allowedAssetInfoList changes
    const networks = cidJson && [
      ...Object.values(cidJson?.testnet),
      ...Object.values(cidJson?.mainnet),
    ];
    const nativeCoinNetwork = networks?.find((network) => network.token === "");
    setAssets(
      allowedAssetInfoList.map((asset) => {
        const matchNetwork = networks.find(
          (network) => network.token === asset.assetAddress
        );
        return {
          id: asset.assetAddress,
          name:
            matchNetwork !== undefined
              ? matchNetwork?.currencySymbol
              : nativeCoinNetwork.currencySymbol,
          icon: (
            <img
              style={{ width: "20px" }}
              src={matchNetwork ? matchNetwork.img : nativeCoinNetwork.img}
              alt={`${
                matchNetwork
                  ? matchNetwork.currencySymbol
                  : nativeCoinNetwork.currencySymbol
              } icon`}
            />
          ),
          balance: BigNumber(asset.info.inAppBalance)
            .dividedBy(
              `1e${
                matchNetwork ? matchNetwork.decimal : nativeCoinNetwork.decimal
              }`
            )
            .toString(),
        };
      })
    );
  }, [allowedAssetInfoList, chargeSuccess, withdrawSuccess, cidJson]);

  const handleCharge = (values) => {
    console.log("Charge:", values);
  };

  const handleWithdraw = (values) => {
    console.log("Withdraw:", values);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <Layout>
      <div className="container mx-auto flex flex-col xl:flex-row gap-6 justify-between relative">
        <div className="xl:w-1/2 h-full bg-white p-6 rounded-2xl">
          <Spin spinning={loading !== "succeeded" && "failed"}>
            <BlockchainAssetsList
              assets={assets}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </Spin>
        </div>
        <div
          className="flex flex-col xl:w-1/2 w-full rounded-2xl p-6"
          style={{
            background: `url(${background}) 0% 0% / cover no-repeat`,
          }}
        >
          <ChargeAccountForm
            assets={assets}
            onCharge={handleCharge}
            setChargeSuccess={setChargeSuccess}
          />
          <WithdrawAssetForm
            assets={assets}
            onWithdraw={handleWithdraw}
            setWithdrawSuccess={setWithdrawSuccess}
          />
        </div>
      </div>
    </Layout>
  );
}
