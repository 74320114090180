import { List, Pagination } from "antd";

function BlockchainAssetsList({ assets, currentPage, onPageChange }) {
  const pageSize = 5;
  const handleChange = (page) => {
    onPageChange(page);
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">My Wallet</h2>
      <List
        itemLayout="horizontal"
        dataSource={assets.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )}
        renderItem={(item) => (
          <List.Item>
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center">
                <span>{item.icon}</span>
                <span className="text-lg font-bold text-dark-gray tracking-wide ml-2">
                  {item.name}
                </span>
              </div>
              <span className="text-lg font-bold text-dark-gray">
                {item.balance}
              </span>
            </div>
          </List.Item>
        )}
      />
      <div className="flex justify-center">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={assets.length}
          onChange={handleChange}
          className="mt-4 "
        />
      </div>
    </div>
  );
}

export default BlockchainAssetsList;
