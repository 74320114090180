import { ConnectButton } from "@rainbow-me/rainbowkit";
import Icons from "../Icons";

export default function RainbowCustomButton() {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            className="flex justify-center w-full"
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div
                    onClick={openConnectModal}
                    className="flex items-center lg:justify-between h-full"
                  >
                    <span className="m-2">
                      <Icons name="wallet" />
                    </span>
                    <p className="md:block hidden lg:text-xl text-lg font-bold text-white">
                      Connect
                    </p>
                  </div>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    className="text-xl font-bold text-white"
                  >
                    Wrong network
                  </button>
                );
              }

              return (
                <div
                  className="flex items-center lg:justify-between w-full h-full"
                  style={{
                    display: "flex",
                    gap: 12,
                    fontSize: 16,
                    color: "#fff",
                  }}
                >
                  <button
                    onClick={openChainModal}
                    style={{ display: "flex", alignItems: "center" }}
                    className="bg-indigo-400 rounded-2xl pr-1"
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 22,
                          height: 22,
                          borderRadius: 999,
                          overflow: "hidden",
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 22, height: 22 }}
                          />
                        )}
                      </div>
                    )}
                    {chain.name.length > 5
                      ? `${chain.name.substring(0, 3)}...`
                      : chain.name}
                  </button>
                  <button
                    onClick={openAccountModal}
                    type="button"
                    className="pr-2 pl-0.5 bg-indigo-400 rounded-2xl"
                  >
                    {window.innerWidth < 768
                      ? `${account.displayName.substring(0, 3)}...`
                      : account.displayName}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
