import createAxiosInstance from ".";

export async function getCidApi(baseURL) {
  try {
    const axiosInstance = createAxiosInstance(baseURL);
    const response = await axiosInstance.get("/");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export async function getAbiApi(baseURL) {
  try {
    const axiosInstance = createAxiosInstance(baseURL);
    const response = await axiosInstance.get("/");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export async function getUserProfileAbi(baseURL) {
  try {
    const axiosInstance = createAxiosInstance(baseURL);
    const response = await axiosInstance.get("/");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export async function getUserProfileInfo(baseURL) {
  try {
    const axiosInstance = createAxiosInstance(baseURL);
    const response = await axiosInstance.get("/");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
