import { useSelector } from "react-redux";
import { SelectUserInfo } from "../../store/getUserProfile/getUserProfileSlice";

const authProfilePic = `${process.env.PUBLIC_URL}/assets/images/auth-profile-picture.png`;
const profileBanner = `${process.env.PUBLIC_URL}/assets/images/profile-cover.png`;

export default function ProfileDescription() {
  const userInfo = useSelector(SelectUserInfo);
  return (
    <>
      <div className="auth-cover w-full">
        <div className="w-full lg:h-[312px] h-[212px] rounded-2xl overflow-hidden">
          <img
            src={profileBanner}
            alt="banner"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="auth lg:flex justify-between items-center mb-16">
        <div className="auth-user-details sm:px-10 px-0 mb-5 lg:mb-0 relative">
          {/* profile picture */}
          <div className=" profile-picture sm:block flex justify-center items-center mb-4">
            <img
              src={
                userInfo?.profileImgUrl
                  ? userInfo.profileImgUrl
                  : authProfilePic
              }
              alt=""
              className="md:w-[205px] md:h-[205px] w-[130px] h-[130px] object-cover rounded-full overflow-hidden border-[6px] border-white"
            />
          </div>
          <div className="auth-user-profile-content">
            <div className="mb-4">
              <h1 className="text-dark-gray tracking-wide sm:text-26 text-xl font-bold">
                {userInfo?.firstName || userInfo?.lastName
                  ? `${userInfo?.firstName} ${userInfo?.lastName}`
                  : ""}
              </h1>
            </div>
          </div>
        </div>
        <div className="flex space-x-5 ml-0 sm:ml-10 lg:ml-0"></div>
      </div>
    </>
  );
}
