import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// loading: 'idle' | 'pending' | 'succeeded' | 'failed'
const initialState = {
  providerRegistry: "0x0",
  loading: "idle",
  error: false,
};

export const getProviderRegistry = createAsyncThunk(
  "contract/getProviderRegistry",
  async ({ address, payContract }) => {
    const response = await payContract.methods
      .getProviderRegistry(address)
      .call();
    return response;
  }
);

const getProviderRegistrySlice = createSlice({
  name: "getProviderRegistry",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getProviderRegistry.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getProviderRegistry.fulfilled, (state, action) => {
        state.providerRegistry = action.payload;
        state.loading = "succeeded";
        state.error = false;
      })
      .addCase(getProviderRegistry.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

export default getProviderRegistrySlice.reducer;
