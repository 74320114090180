const imageUrl = `${process.env.PUBLIC_URL}/assets/images/bank-6.png`;

export default function CustomAvatar({ size = 12 }) {
  return (
    <img
      src={imageUrl}
      alt="Laqira"
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
    />
  );
}
