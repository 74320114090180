import { useMemo } from "react";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import * as cidSlice from "../store/getCid/getCidSlice";

const useWeb3Contract = ({ selectedAsset = null }) => {
  const { address } = useAccount();

  const contractAddress = useSelector(cidSlice.selectContractAddress);
  const contractPayAbi = useSelector(cidSlice.selectContractPayAbi);

  const userProfileAddress = useSelector(cidSlice.selectUserProfileAddress);
  const userProfileAbi = useSelector(cidSlice.selectUserProfileAbi);

  const contractTokenAbi = useSelector(cidSlice.selectContractTokenAbi);

  const contractRPC = useSelector(cidSlice.selectContractRPC);

  const payContract = useMemo(() => {
    if (!contractPayAbi || !contractAddress || !address) {
      return null;
    }
    try {
      const web3 = new Web3(contractRPC);
      return new web3.eth.Contract(contractPayAbi, contractAddress);
    } catch (error) {
      console.error("Error creating contract", error);
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractAddress, contractPayAbi, address]);

  const userProfileContract = useMemo(() => {
    if (!userProfileAbi || !userProfileAddress || !address) {
      return null;
    }

    try {
      const web3 = new Web3(contractRPC);
      return new web3.eth.Contract(userProfileAbi, userProfileAddress);
    } catch (error) {
      console.error("Error creating contract", error);
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileAddress, userProfileAbi, address]);

  const tokenContract = useMemo(() => {
    if (!contractTokenAbi || !selectedAsset || !address) {
      return null;
    }

    try {
      const web3 = new Web3(contractRPC);
      return new web3.eth.Contract(contractTokenAbi, selectedAsset);
    } catch (error) {
      console.error("Error creating contract", error);
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset, contractTokenAbi, address]);

  return {
    payContract,
    userProfileContract,
    tokenContract,
  };
};

export default useWeb3Contract;
