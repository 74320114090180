import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLqrPrice } from "../../store/getLqrPrice/getLqrPriceSlice";

export default function SearchCom() {
  const [livePrice, setLiverPrice] = useState("0");
  const dispatch = useDispatch();
  const lqr = useSelector((state) => state.getLqrPrice.price);
  const livePriceLQRToken = async () => {
    dispatch(getLqrPrice());
    setLiverPrice(`LQR token : ${lqr}`);
  };
  useEffect(() => {
    livePriceLQRToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lqr]);
  return (
    <div
      className={`w-full h-[48px]  pl-8 flex rounded-full overflow-hidden bg-white ${""}`}
    >
      <div className="flex-1 h-full">
        <input
          className={`w-full h-full focus:outline-0 focus:ring-0 ${""}`}
          type="text"
          placeholder={livePrice}
          disabled
        />
      </div>
    </div>
  );
}
