/* eslint-disable react-hooks/exhaustive-deps */
import Web3 from "web3";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button } from "antd";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import {
  selectContractAddress,
  selectContractPayAbi,
  selectContractRPC,
  selectContractTokenAbi,
} from "../../store/getCid/getCidSlice";
import useConfirmTransaction from "../../hooks/useConfirmTransaction";

function Token({ asset, amount, isRegistered }) {
  const contractRPC = useSelector(selectContractRPC);
  const tokenAbi = useSelector(selectContractTokenAbi);
  const payAbi = useSelector(selectContractPayAbi);
  const contractAddress = useSelector(selectContractAddress);
  const [allowance, setAllowance] = useState("");
  const [approveLoading, setApproveLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const { address } = useAccount();

  const allowanceFn = async () => {
    const web3 = new Web3(contractRPC);
    const contractToken = new web3.eth.Contract(tokenAbi, asset);

    try {
      const fetchedAllowance = await contractToken.methods
        .allowance(address, contractAddress)
        .call();
      setAllowance(fetchedAllowance.toString());
      return fetchedAllowance;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  useEffect(() => {
    allowanceFn();
  }, [address, contractAddress]);

  // approve process
  const approve = useConfirmTransaction({
    address: asset,
    abi: tokenAbi,
    functionName: "approve",
  });

  useEffect(() => {
    if (approve.status !== "idle" && approve.status !== "loading") {
      if (approve.receipt?.status) {
        setApproved(true);
      } else {
        toast.warn("Something went wrong, try later again!");
      }
      setApproveLoading(false);
    }
  }, [approve.status]);

  useEffect(() => {
    if (approve?.error && approve.includes("User rejected the request"))
      setApproveLoading(false);
  }, [approve?.error]);

  const handleApprove = async () => {
    setApproveLoading(true);
    await approve.handleTransaction(
      [
        contractAddress,
        115792089237316195423570985008687907853269984665640564039457584007913129639935n,
      ],
      address
    );
  };

  // register process
  const register = useConfirmTransaction({
    address: contractAddress,
    abi: payAbi,
    functionName: "providerRegister",
  });

  useEffect(() => {
    if (register.status !== "idle" && register.status !== "loading") {
      if (register.receipt?.status) {
        toast.success("Registered successfully");
      } else {
        toast.warn("Something went wrong, try later again!");
      }
    }
  }, [register.status]);

  const handleRegister = async () => {
    await register.handleTransaction([asset], address);
  };

  return (
    <>
      <Button
        disabled={allowance > amount || approved}
        loading={approveLoading}
        onClick={handleApprove}
        type="primary"
        className="w-full bg-blue-500 text-white hover:bg-current mb-4"
      >
        Approve
      </Button>

      <Button
        onClick={handleRegister}
        disabled={
          (!(allowance > amount) && !approved) || isRegistered || !address
        }
        type="primary"
        className="w-full bg-blue-500 text-white hover:bg-current"
        loading={register.isLoading}
      >
        Register
      </Button>
    </>
  );
}

export default Token;
