/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Select, Input, Button } from "antd";
import { useAccount } from "wagmi";
import Big from "big.js";
import { toast } from "react-toastify";
import {
  selectContractAddress,
  selectContractPayAbi,
} from "../../store/getCid/getCidSlice";
import useConfirmTransaction from "../../hooks/useConfirmTransaction";

export default function WithdrawAssetForm({
  assets,
  onWithdraw,
  setWithdrawSuccess,
}) {
  const [form] = Form.useForm();
  const { address } = useAccount();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [maxWithdrawAble, setMaxWithdrawAble] = useState(0);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const payAbi = useSelector(selectContractPayAbi);
  const contractAddress = useSelector(selectContractAddress);

  const withdraw = useConfirmTransaction({
    address: contractAddress,
    abi: payAbi,
    functionName: "withdrawAsset",
  });

  useEffect(() => {
    if (withdraw.status !== "idle" && withdraw.status !== "loading") {
      if (withdraw.receipt?.status) {
        toast.success("Withdraw successful");
        setSelectedAsset(null);
        setWithdrawSuccess(true);
        setTimeout(() => setWithdrawSuccess(false), 10000);
      } else {
        toast.warn("Something went wrong, try later again!");
      }
      setWithdrawLoading(false);
      form.resetFields();
    }
  }, [withdraw.status]);

  useEffect(() => {
    if (
      withdraw?.error &&
      withdraw?.error.toString().includes("User rejected the request")
    )
      setWithdrawLoading(false);
  }, [withdraw?.error]);

  const handleSubmit = async (values) => {
    setWithdrawLoading(true);
    await withdraw.handleTransaction([selectedAsset, selectedAmount], address);
    onWithdraw(values);
  };

  const handleAssetChange = (value) => {
    const data = value.split("|");
    setSelectedAsset(data[0]);
    setMaxWithdrawAble(data[1]);
  };

  const handleAmountChange = (event) => {
    const amount = event.target.value;
    const numericValue = Number(amount);
    const bigValue = new Big(numericValue).times("1e18");
    const valueInBig = bigValue.toFixed();
    setSelectedAmount(valueInBig);
  };
  return (
    <div className="my-8">
      <h2 className="text-xl tracking-wide text-white opacity-[90%] font-semibold mb-4">
        Withdraw Asset
      </h2>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="inline"
        style={{ marginBottom: "30px" }}
      >
        <Form.Item name="asset" label="Select Asset">
          <Select
            popupMatchSelectWidth={false}
            style={{ width: "110px" }}
            onChange={handleAssetChange}
          >
            {assets.map((asset) => (
              <Select.Option
                key={asset.id}
                value={`${asset.id}|${asset.balance}`}
              >
                {asset.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="amount" label="Amount">
          <Input type="number" className="w-16" onChange={handleAmountChange} />
        </Form.Item>
        <Form.Item>
          <Button
            loading={withdrawLoading}
            disabled={
              !selectedAmount ||
              selectedAmount === "0" ||
              !selectedAsset ||
              selectedAmount - new Big(maxWithdrawAble).times("1e18") > 0
            }
            type="primary"
            htmlType="submit"
            className="bg-blue-400 hover:bg-blue-800 texst-white transition duration-300"
            style={{ color: "#fff" }}
          >
            Withdraw
          </Button>
        </Form.Item>
      </Form>
      {!!selectedAsset && (
        <label
          style={{
            color: "white",
          }}
        >
          Remaining Balance: {maxWithdrawAble}
        </label>
      )}
    </div>
  );
}
