import Layout from "../components/Partials/Layout";
import NewHomeDesigned from "../components/Home/NewHomeDesigned";

export default function HomePages() {
  return (
    <Layout>
      <div className="home-page-wrapper">
        <NewHomeDesigned />
      </div>
    </Layout>
  );
}
