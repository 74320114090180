import { createSelector } from "reselect";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// loading: 'idle' | 'pending' | 'succeeded' | 'failed'
const initialState = {
  inAppBalanceMap: {},
  loading: "idle",
  error: false,
};

export const getInAppBalanceAsync = createAsyncThunk(
  "getInAppBalance",
  async ({ params, payContract }) => {
    const { userAddress, assetAddress } = params;
    try {
      const response = await payContract.methods
        .getInAppBalance(userAddress, assetAddress)
        .call();
      return {
        assetAddress,
        info: {
          inAppBalance: response.toString(),
        },
      };
    } catch (err) {
      return console.log(err);
    }
  }
);

const getInAppBalanceSlice = createSlice({
  name: "getInAppBalance",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInAppBalanceAsync.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getInAppBalanceAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const { assetAddress, info } = action.payload;
          state.inAppBalanceMap = {
            ...state.inAppBalanceMap,
            [assetAddress]: info,
          };
          state.loading = "succeeded";
          state.error = false;
        }
      })
      .addCase(getInAppBalanceAsync.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});
export const selectInAppBalanceMap = (state) =>
  state.getInAppBalance.inAppBalanceMap;

export const selectAssetInfo = createSelector(
  [selectInAppBalanceMap],
  (inAppBalanceMap) =>
    Object.entries(inAppBalanceMap).map(([assetAddress, info]) => ({
      assetAddress,
      info,
    }))
);

export const selectLoading = (state) => state?.getInAppBalance?.loading;

export default getInAppBalanceSlice.reducer;
