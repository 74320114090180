import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { contract } from "../../utils/blockchainConnection.utils";

// loading: 'idle' | 'pending' | 'succeeded' | 'failed'
const initialState = {
  feeAssets: [],
  currentAsset: 0,
  loading: "idle",
  error: false,
};

export const getFeeAssets = createAsyncThunk(
  "contract/getFeeAssets",
  async ({ payContract }) => {
    const response = await payContract.methods.getFeeAssets().call();
    return response;
  }
);

const changeAsset = (state, action) => {
  state.currentAsset = action.payload;
};

const getFeeAssetsSlice = createSlice({
  name: "getFeeAssets",
  initialState,
  reducers: {
    changeAsset,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeeAssets.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getFeeAssets.fulfilled, (state, action) => {
        state.feeAssets = action.payload;
        state.loading = "succeeded";
        state.error = false;
      })
      .addCase(getFeeAssets.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

export const { changeAsset: changeAssetAction } = getFeeAssetsSlice.actions;

export default getFeeAssetsSlice.reducer;
