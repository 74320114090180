import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { contract } from "../../utils/cidConnection.utils";
import { getAbiApi, getCidApi, getUserProfileAbi } from "../../apis/CidApi";

// Initial State
const initialState = {
  cidJson: null,
  contractAddress: "",
  tokenAddress: "",
  userProfileAddress: "",
  userProfileAbi: {},
  contractPayAbi: {},
  contractTokenAbi: {},
  rpc: "",
  wcpi: "",
  loading: "idle",
  error: false,
};

// Async Thunks
export const getCidAsync = createAsyncThunk("Cid/config", async (chainId) => {
  try {
    const link = await contract.methods.getCid().call();
    try {
      const json = await getCidApi(link);
      const findRecordByChainId = (networks, _chainId) =>
        networks.find((network) => network.chain_id === _chainId);

      const chainConfig = findRecordByChainId(
        [...Object.values(json.testnet), ...Object.values(json.mainnet)],
        chainId
      );

      const address = chainConfig.contract;
      const contractRPC = chainConfig.rpc;
      const profileContract = chainConfig?.profileContract;

      const payAbi = await getAbiApi(chainConfig.abi_contract_1);
      const tokenAbi = await getAbiApi(chainConfig.abi_contract_2);
      const profileAbi = await getUserProfileAbi(chainConfig.abi_contract_4);

      return {
        json,
        contractRPC,
        address,
        payAbi,
        tokenAbi,
        profileAbi,
        profileContract,
      };
    } catch (err) {
      console.log(err);
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
});

// Async Thunks
export const getCidWcpiAsync = createAsyncThunk("Cid/wcpi", async () => {
  try {
    const link = await contract.methods.getCid().call();
    try {
      const json = await getCidApi(link);
      const { wcpi } = json;

      return {
        wcpi,
      };
    } catch (err) {
      console.log(err);
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
});

// Slice
const getCidSlice = createSlice({
  name: "getCid",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCidAsync.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getCidAsync.fulfilled, (state, action) => {
        state.cidJson = action.payload.json;
        state.contractPayAbi = action.payload.payAbi;
        state.contractTokenAbi = action.payload.tokenAbi;
        state.rpc = action.payload.contractRPC;
        state.userProfileAbi = action.payload.profileAbi;
        state.contractAddress = action.payload.address;
        state.userProfileAddress = action.payload.profileContract;
        state.tokenAddress = action.payload.token;
        state.loading = "succeeded";
      })
      .addCase(getCidAsync.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
    builder
      .addCase(getCidWcpiAsync.pending, (state) => {
        state.loading = "pending";
        state.error = false;
      })
      .addCase(getCidWcpiAsync.fulfilled, (state, action) => {
        state.wcpi = action.payload.wcpi;
        state.loading = "succeeded";
      })
      .addCase(getCidWcpiAsync.rejected, (state) => {
        state.loading = "failed";
        state.error = true;
      });
  },
});

// export const {} = getCidSlice.actions;

export const selectCidJson = (state) => state?.getCid?.cidJson;
export const selectCidLoading = (state) => state?.getCid?.loading;
export const selectContractAddress = (state) => state?.getCid?.contractAddress;
export const selectTokenAddress = (state) => state?.getCid?.tokenAddress;
export const selectUserProfileAddress = (state) =>
  state?.getCid?.userProfileAddress;
export const selectContractPayAbi = (state) => state?.getCid?.contractPayAbi;
export const selectContractTokenAbi = (state) =>
  state?.getCid?.contractTokenAbi;
export const selectUserProfileAbi = (state) => state?.getCid?.userProfileAbi;
export const selectContractRPC = (state) => state?.getCid?.rpc;
export const selectWcpi = (state) => state?.getCid?.wcpi;

export default getCidSlice.reducer;
