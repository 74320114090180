import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { Col, Row, Spin } from "antd";
import {
  SelectIsRegistered,
  SelectLoading,
} from "../../store/getRegistryInfo/getRegistryInfoSlice";

export default function NewHomeDesigned() {
  const { openConnectModal } = useConnectModal();
  const { isConnected } = useAccount();
  const navigate = useNavigate();
  const isRegistered = useSelector(SelectIsRegistered);
  const loading = useSelector(SelectLoading);

  const handleRegistration = () => {
    // Redirect to the registration page
    if (!isConnected) {
      openConnectModal();
    } else {
      navigate("/register");
    }
  };
  return (
    <>
      <Helmet>
        <script src="https://public.bnbstatic.com/unpkg/growth-widget/cryptoCurrencyWidget@0.0.9.min.js"></script>
      </Helmet>
      <Spin spinning={loading === "pending"}>
        <div className="container mx-auto p-4 relative">
          <div
            className="binance-widget-marquee"
            data-cmc-ids="1,1027,1839,52,3408,5805,3890,7083"
            data-theme="light"
            data-transparent="false"
            data-locale="en"
            data-powered-by="Powered by"
            data-disclaimer="Disclaimer"
          ></div>
          <div className="mt-4">
            <div className="bg-blue-100 rounded p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-blue-400" />
                </div>

                {/* Registration Section */}
                {!isRegistered ? (
                  <div className="mt-8">
                    <p className="text-lg font-bold">
                      You haven't registered as a provider. Do you wish to
                      become one?
                    </p>
                    <button
                      type="button"
                      onClick={handleRegistration}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2"
                    >
                      Register as Provider
                    </button>
                  </div>
                ) : (
                  <p className="text-lg font-bold pt-4 pb-4">
                    Thank you for registering! Start exploring our app and make
                    the most of your experience.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-xl font-bold text-gray-900">
              Recent Transactions
            </div>
            <div>
              {!isConnected ? (
                <div className="mt-4 bg-white rounded-md border border-gray-200">
                  <div className="flex flex-col items-center pt-16 pb-8">
                    <svg
                      className="w-16 h-16 text-blue-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                      ></path>
                    </svg>

                    <p className="mt-6 text-xl font-semibold text-center text-gray-900">
                      Please connect your wallet
                      <br />
                      to view your recent transactions
                    </p>
                  </div>
                </div>
              ) : (
                <Row gutter={[16, 8]} style={{ marginTop: "20px" }}>
                  <Col span={12}>
                    <div className="text-xl text-green-800">
                      Deposits &#8601;
                    </div>
                    <div className="mt-4 bg-white rounded-md border border-gray-200">
                      <div className="flex p-4 border-b border-gray-200">
                        <div>
                          <div className="text-gray-500">From</div>
                          <div className="font-medium">0x293...</div>
                        </div>
                        <div className="ml-auto">
                          <div className="text-gray-500">Value</div>
                          <div className="font-medium">0.059 ETH</div>
                        </div>
                      </div>

                      <div className="flex p-4 border-b border-gray-200">
                        <div>
                          <div className="text-gray-500">From</div>
                          <div className="font-medium">0x293...</div>
                        </div>
                        <div className="ml-auto">
                          <div className="text-gray-500">Value</div>
                          <div className="font-medium">0.059 ETH</div>
                        </div>
                      </div>

                      <div className="flex p-4 border-b border-gray-200">
                        <div>
                          <div className="text-gray-500">From</div>
                          <div className="font-medium">0x293...</div>
                        </div>
                        <div className="ml-auto">
                          <div className="text-gray-500">Value</div>
                          <div className="font-medium">0.059 ETH</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="text-xl text-red-800">
                      Withdrawals &#8599;
                    </div>
                    <div className="mt-4 bg-white rounded-md border border-gray-200">
                      <div className="flex p-4 border-b border-gray-200">
                        <div>
                          <div className="text-gray-500">From</div>
                          <div className="font-medium">0x293...</div>
                        </div>
                        <div className="ml-auto">
                          <div className="text-gray-500">Value</div>
                          <div className="font-medium">0.059 ETH</div>
                        </div>
                      </div>

                      <div className="flex p-4 border-b border-gray-200">
                        <div>
                          <div className="text-gray-500">From</div>
                          <div className="font-medium">0x293...</div>
                        </div>
                        <div className="ml-auto">
                          <div className="text-gray-500">Value</div>
                          <div className="font-medium">0.059 ETH</div>
                        </div>
                      </div>

                      <div className="flex p-4 border-b border-gray-200">
                        <div>
                          <div className="text-gray-500">From</div>
                          <div className="font-medium">0x293...</div>
                        </div>
                        <div className="ml-auto">
                          <div className="text-gray-500">Value</div>
                          <div className="font-medium">0.059 ETH</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}
