import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useAccount } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import {
  SelectIsRegistered,
  SelectLoading,
  changeRegistryInfoAction,
} from "../../../store/getRegistryInfo/getRegistryInfoSlice";
import { selectCidLoading } from "../../../store/getCid/getCidSlice";

export default function ProviderRegistry() {
  const registerLoading = useSelector(SelectLoading);
  const cidLoading = useSelector(selectCidLoading);

  const dispatch = useDispatch();
  const { address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const isRegistered = useSelector(SelectIsRegistered);
  useEffect(() => {
    if (address) {
      // dispatch(getRegistryInfoAsync(address));
    } else {
      dispatch(changeRegistryInfoAction(false));
    }
  }, [address, dispatch]);

  return (
    <div>
      {isRegistered ? (
        ""
      ) : (
        <div className="flex items-center">
          {registerLoading !== "pending" && cidLoading !== "pending" ? (
            <p className="text-slate-500 text-sm hidden md:block">
              You haven't registered as a provider, wish to do?
            </p>
          ) : (
            ""
          )}

          <div className="flex justify-center items-center md:py-2 md:px-4 rounded hover:text-purple transition duration-300 hover:cursor-pointer">
            {registerLoading !== "pending" && cidLoading !== "pending" ? (
              <Badge dot>
                <Avatar shape="square" size={26} icon={<UserOutlined />} />
              </Badge>
            ) : (
              ""
            )}

            {!address &&
              registerLoading !== "pending" &&
              cidLoading !== "pending" && (
                <div
                  className="ml-2"
                  onClick={(e) => {
                    e.preventDefault();
                    openConnectModal();
                  }}
                >
                  Register Now
                </div>
              )}
            {!!address &&
            registerLoading !== "pending" &&
            cidLoading !== "pending" ? (
              <Link to="/register" className="ml-1">
                Register Now
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
}
