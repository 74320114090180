import { useEffect } from "react";
import { useAccount } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { Badge } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Icons from "../Helpers/Icons";
import { SelectIsRegistered } from "../../store/getRegistryInfo/getRegistryInfoSlice";
import { SelectDrawer } from "../../store/drawer";
import { ReactComponent as TelegramIcon } from "../../icons/telegram.svg";
import { ReactComponent as YoutubeIcon } from "../../icons/youtube.svg";
import { ReactComponent as TwitterIcon } from "../../icons/twitter.svg";
import { ReactComponent as DiscordIcon } from "../../icons/discord.svg";
import { ReactComponent as MediumIcon } from "../../icons/medium.svg";

const logo = `${process.env.PUBLIC_URL}/assets/images/logo_laqira_pay.png`;
const lqrLogo = `${process.env.PUBLIC_URL}/assets//images/bank-7.png`;

export default function Sidebar({ sidebar, action }) {
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { address } = useAccount();
  const isRegistered = useSelector(SelectIsRegistered);
  const drawer = useSelector(SelectDrawer);
  // logoutModalHandler
  useEffect(() => {
    const title = document.querySelectorAll(".menu-setting-items .heading");
    if (sidebar) {
      title.forEach((elm) => {
        elm.classList.add("active");
      });
    } else {
      title.forEach((elm) => {
        elm.classList.remove("active");
      });
    }
  });
  const handleNavLinkClick = () => {
    if (!isConnected) {
      openConnectModal();
    }
  };
  return (
    <div className="w-full h-full">
      {/* logo-area */}
      <div
        className={`w-full flex items-center transition-all duration-500 ease-in-out cubic-bezier(0.52, 0.35, 0.7, 0.37) ${
          sidebar ? "justify-between  mb-8" : "justify-center"
        }`}
      >
        <div className={`sidebar-logo ${sidebar ? "enter" : ""}`}>
          <img src={logo} alt="nft" />
        </div>

        <span onClick={action} className="hover:cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="19"
            viewBox="0 0 25 19"
            fill="none"
          >
            <path
              d="M24.3544 8.45953L16.9855 0.271831C16.8283 0.0982522 16.6089 0 16.3763 0H11.4637C11.1411 0 10.848 0.189955 10.7153 0.484712C10.5843 0.781107 10.6384 1.12663 10.8545 1.36571L17.7306 9.00647L10.8545 16.6456C10.6384 16.8863 10.5827 17.2318 10.7153 17.5266C10.848 17.823 11.1411 18.0129 11.4637 18.0129H16.3763C16.6089 18.0129 16.8283 17.913 16.9855 17.7427L24.3544 9.55505C24.6344 9.24391 24.6344 8.76903 24.3544 8.45953Z"
              fill="url(#paint0_linear_159_67708)"
            />
            <path
              d="M13.7104 8.45953L6.34148 0.271831C6.18427 0.0982522 5.96484 0 5.73231 0H0.819691C0.497095 0 0.203976 0.189955 0.071335 0.484712C-0.0596682 0.781107 -0.00562942 1.12663 0.210526 1.36571L7.08656 9.00647L0.210526 16.6456C-0.00562942 16.8863 -0.0613058 17.2318 0.071335 17.5266C0.203976 17.823 0.497095 18.0129 0.819691 18.0129H5.73231C5.96484 18.0129 6.18427 17.913 6.34148 17.7427L13.7104 9.55505C13.9904 9.24391 13.9904 8.76903 13.7104 8.45953Z"
              fill="url(#paint1_linear_159_67708)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_159_67708"
                x1="10.644"
                y1="0"
                x2="28.9548"
                y2="13.8495"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F539F8" />
                <stop offset="0.416763" stopColor="#C342F9" />
                <stop offset="1" stopColor="#7a53fb" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_159_67708"
                x1="0"
                y1="0"
                x2="18.3108"
                y2="13.8495"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F539F8" />
                <stop offset="0.416763" stopColor="#C342F9" />
                <stop offset="1" stopColor="#7a53fb" />
              </linearGradient>
            </defs>
          </svg>
        </span>
      </div>
      {/* menu and settings item */}
      <div className="menu-setting-items mb-8">
        {/* menus item */}
        <div
          className={`menu-item transition-all duration-300 ease-in-out ${
            sidebar ? "mb-8" : ""
          }`}
        >
          <div className="items">
            <ul className="flex flex-col space-y-6">
              <li className="item group">
                <NavLink
                  className={`nav-item flex items-center ${
                    ((navData) => (navData.isActive ? "active" : ""),
                    sidebar ? "justify-start space-x-3.5" : "justify-center")
                  } `}
                  to="/"
                >
                  <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                    <Icons name="dashboard" />
                  </span>
                  <span
                    className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                      sidebar ? "active flex-1" : "w-0"
                    }`}
                  >
                    Dashboard
                  </span>
                </NavLink>
              </li>

              <li className="item group">
                {isConnected ? (
                  <NavLink
                    to="/my-wallet"
                    className={`nav-item flex items-center ${
                      ((navData) => (navData.isActive ? "active" : ""),
                      sidebar ? "justify-start space-x-3.5" : "justify-center")
                    }`}
                  >
                    <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                      <Icons name="wallet-two" />
                    </span>
                    <span
                      className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                        sidebar ? "active flex-1" : "w-0"
                      }`}
                    >
                      My Wallet
                    </span>
                  </NavLink>
                ) : (
                  <div
                    onClick={handleNavLinkClick}
                    className={`nav-item flex items-center cursor-pointer ${
                      ((navData) => (navData.isActive ? "active" : ""),
                      sidebar ? "justify-start space-x-3.5" : "justify-center")
                    }`}
                  >
                    <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out bg-light-purple rounded-full text-dark-gray">
                      <Icons name="wallet-two" />
                    </span>
                    <span
                      className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                        sidebar ? "active flex-1" : "w-0"
                      }`}
                    >
                      My Wallet
                    </span>
                  </div>
                )}
              </li>
              {isConnected && !isRegistered && (
                <li className="item group">
                  <NavLink
                    to="/register"
                    className={`nav-item flex items-center ${
                      ((navData) => (navData.isActive ? "active" : ""),
                      sidebar ? "justify-start space-x-3.5" : "justify-center")
                    }`}
                  >
                    <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                      <Icons name="active-bids" />
                    </span>
                    <span
                      className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                        sidebar ? "active flex-1" : "w-0"
                      }`}
                    >
                      Register
                    </span>
                  </NavLink>
                </li>
              )}

              {!isConnected && (
                <li className="item group">
                  <div
                    onClick={handleNavLinkClick}
                    className={`nav-item flex items-center cursor-pointer ${
                      ((navData) => (navData.isActive ? "active" : ""),
                      sidebar ? "justify-start space-x-3.5" : "justify-center")
                    }`}
                  >
                    <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out bg-light-purple rounded-full text-dark-gray">
                      <Icons name="active-bids" />
                    </span>
                    <span
                      className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                        sidebar ? "active flex-1" : "w-0"
                      }`}
                    >
                      Register
                    </span>
                  </div>
                </li>
              )}

              {!!address && !!isRegistered && (
                <li className="item group">
                  <NavLink
                    to="/profile"
                    className={`nav-item flex items-center ${
                      ((navData) => (navData.isActive ? "active" : ""),
                      sidebar ? "justify-start space-x-3.5" : "justify-center")
                    }`}
                  >
                    <Badge
                      count={
                        <CheckOutlined
                          style={{
                            color: "rgb(34 197 94)",
                          }}
                        />
                      }
                    >
                      <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                        <Icons name="people-two" />
                      </span>
                    </Badge>

                    <span
                      className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                        sidebar ? "active flex-1" : "w-0"
                      }`}
                    >
                      Profile
                    </span>
                  </NavLink>
                </li>
              )}
              {drawer && (
                <h3 className="text-[16px] font-semibold mb-1 text-purple">
                  Official web
                </h3>
              )}
              <li className="item group">
                <a
                  icon={lqrLogo}
                  href="https://laqira.io/"
                  target="_blank"
                  rel="noreferrer"
                  className={`nav-item flex items-center ${
                    ((navData) => (navData.isActive ? "active" : ""),
                    sidebar ? "justify-start space-x-3.5" : "justify-center")
                  }`}
                >
                  <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                    <Icons name="protocol" />
                  </span>
                  <span
                    className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                      sidebar ? "active flex-1" : "w-0"
                    }`}
                  >
                    Laqira Protocol
                  </span>
                </a>
              </li>

              {drawer && (
                <h3 className="text-[16px] font-semibold mb-1 text-purple">
                  Products
                </h3>
              )}
              <li className="item group">
                <a
                  href="https://tabit.laqira.com/"
                  target="_blank"
                  rel="noreferrer"
                  className={`nav-item flex items-center ${
                    ((navData) => (navData.isActive ? "active" : ""),
                    sidebar ? "justify-start space-x-3.5" : "justify-center")
                  }`}
                >
                  <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                    <Icons name="tabit" />
                  </span>
                  <span
                    className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                      sidebar ? "active flex-1" : "w-0"
                    }`}
                  >
                    TaBit DApp
                  </span>
                </a>
              </li>
              <li className="item group">
                <a
                  href="https://marketplace.laqira.io/"
                  target="_blank"
                  rel="noreferrer"
                  className={`nav-item flex items-center ${
                    ((navData) => (navData.isActive ? "active" : ""),
                    sidebar ? "justify-start space-x-3.5" : "justify-center")
                  }`}
                >
                  <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                    <Icons name="nft" />
                  </span>
                  <span
                    className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                      sidebar ? "active flex-1" : "w-0"
                    }`}
                  >
                    NFT Marketplace
                  </span>
                </a>
              </li>

              <li className="item group">
                <a
                  href="https://laqirace.com/"
                  target="_blank"
                  rel="noreferrer"
                  className={`nav-item flex items-center ${
                    ((navData) => (navData.isActive ? "active" : ""),
                    sidebar ? "justify-start space-x-3.5" : "justify-center")
                  }`}
                >
                  <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                    <Icons name="laqirace" />
                  </span>
                  <span
                    className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                      sidebar ? "active flex-1" : "w-0"
                    }`}
                  >
                    Laqirace Game
                  </span>
                </a>
              </li>
              <li className="item group">
                <a
                  href="https://dex.laqira.com/"
                  target="_blank"
                  rel="noreferrer"
                  className={`nav-item flex items-center ${
                    ((navData) => (navData.isActive ? "active" : ""),
                    sidebar ? "justify-start space-x-3.5" : "justify-center")
                  }`}
                >
                  <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                    <Icons name="exchange" />
                  </span>
                  <span
                    className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                      sidebar ? "active flex-1" : "w-0"
                    }`}
                  >
                    Laqira Dex
                  </span>
                </a>
              </li>

              {drawer && (
                <h3 className="text-[16px] text-purple font-semibold mb-1">
                  Services
                </h3>
              )}
              <li className="item group">
                <a
                  href="https://laqira.io/laqira-lab"
                  target="_blank"
                  rel="noreferrer"
                  className={`nav-item flex items-center ${
                    ((navData) => (navData.isActive ? "active" : ""),
                    sidebar ? "justify-start space-x-3.5" : "justify-center")
                  }`}
                >
                  <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                    <Icons name="lab" />
                  </span>
                  <span
                    className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                      sidebar ? "active flex-1" : "w-0"
                    }`}
                  >
                    Laqira Lab
                  </span>
                </a>
              </li>
              {drawer && (
                <h3 className="text-[16px] font-semibold mb-1 text-purple">
                  Documentation
                </h3>
              )}
              <li className="item group">
                <NavLink
                  to="/laqira-pay"
                  className={`nav-item flex items-center ${
                    ((navData) => (navData.isActive ? "active" : ""),
                    sidebar ? "justify-start space-x-3.5" : "justify-center")
                  }`}
                >
                  <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple rounded-full text-dark-gray">
                    <Icons name="pay" />
                  </span>
                  <span
                    className={`item-content group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                      sidebar ? "active flex-1" : "w-0"
                    }`}
                  >
                    Laqira Pay
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
          {drawer && (
            <div className="flex justify-start mt-3">
              <SocialLink
                url="https://t.me/laqira_official"
                svg={<TelegramIcon />}
              />
              <SocialLink
                url="https://www.youtube.com/channel/UCf_AmXXqUvFsnoWCv2liSUg"
                svg={<YoutubeIcon />}
              />
              <SocialLink
                url="https://twitter.com/LaqiraProtocol"
                svg={<TwitterIcon />}
              />
              <SocialLink
                url="https://discord.com/invite/ZGW3Ga7AKh"
                svg={<DiscordIcon />}
              />
              <SocialLink
                url="https://medium.com/@laqiraprotocol"
                svg={<MediumIcon />}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SocialLink({ url, svg }) {
  return (
    <a
      href={url}
      target="_blank"
      className="flex items-center p-2 pl-0 rounded"
      rel="noreferrer"
    >
      <span className="w-5 h-5 flex items-center justify-center rounded-full transition duration-300 bg-[#5F3DA8] hover:bg-[#af9cff] fill-slate-50">
        {svg}
      </span>
    </a>
  );
}
