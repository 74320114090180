import React, { useEffect, useState, useRef } from "react";
import Clipboard from "react-clipboard.js";
import { useAccount } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Switch, Tooltip, Modal } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getProviderRegistry } from "../store/getProviderRegistry/getProviderRegistry";
import {
  changeDirectPayAction,
  getDirectPay,
} from "../store/getDircetPay/getDircetPaySlice";
import Layout from "../components/Partials/Layout";
import ProfileDescription from "../components/Profile/ProfileDescription";
import { SelectIsRegistered } from "../store/getRegistryInfo/getRegistryInfoSlice";
import {
  selectContractAddress,
  selectContractPayAbi,
} from "../store/getCid/getCidSlice";
import useConfirmTransaction from "../hooks/useConfirmTransaction";
import useWeb3Contract from "../hooks/useContract";
import PersonalInfoTab from "../components/Settings/PersonalInfo";

const profile = `${process.env.PUBLIC_URL}/assets/images/profile-info-profile.png`;

function CopyButton({ text, onSuccess, copied }) {
  const onCopy = () => {
    onSuccess();
  };
  return (
    <Clipboard component="div" data-clipboard-text={text} onSuccess={onCopy}>
      <Tooltip title={copied ? "Copied" : "Copy to clipboard"}>
        <CopyOutlined
          style={{
            backgroundColor: "#dddce0",
            padding: "8px",
            borderRadius: "50%",
            border: "1px solid #dddce0",
            fontSize: 16,
            cursor: "pointer",
          }}
          onClick={onCopy}
        />
      </Tooltip>
    </Clipboard>
  );
}

export default function AuthProfilePage() {
  const [hasNewProfileImg, setHasNewProfileImg] = useState(false);
  const { address } = useAccount();
  const navigate = useNavigate();
  const isRegistered = useSelector(SelectIsRegistered);
  const dispatch = useDispatch();
  const { payContract } = useWeb3Contract({});
  const providerRegistry = useSelector(
    (state) => state.getProviderRegistry.providerRegistry
  );
  const directPay = useSelector((state) => state?.getDirectPay?.directPay);
  const [copied, setCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const payAbi = useSelector(selectContractPayAbi);
  const contractAddress = useSelector(selectContractAddress);

  useEffect(() => {
    if (address && isRegistered) {
      dispatch(getProviderRegistry({ address, payContract }));
      dispatch(getDirectPay({ address, payContract }));
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isRegistered]);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const { status, receipt, handleTransaction, error } = useConfirmTransaction({
    address: contractAddress,
    abi: payAbi,
    functionName: "setDirectPay",
  });

  useEffect(() => {
    if (status !== "idle" && status !== "loading") {
      if (receipt?.status) {
        dispatch(changeDirectPayAction(!directPay));
        setShowModal(false);
        toast.success("Payment status successfully changed.");
      } else {
        setShowModal(false);
        toast.warn("There was an error.");
      }
      setConfirmLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (error && error.toString().includes("User rejected the request")) {
      setConfirmLoading(false);
      setShowModal(false);
    }
  }, [error]);

  const handleToggleChange = async () => {
    setConfirmLoading(true);
    await handleTransaction([!directPay], address);
  };

  const [profileImgPreview, setProfileImgPreview] = useState(profile);
  const [profileImg, setProfileImg] = useState(profile);

  // profile img
  const profileImgInput = useRef(null);
  const browseProfileImg = () => {
    profileImgInput.current.click();
  };
  const profileImgChangHandler = (e) => {
    if (e.target.value !== "") {
      setProfileImg(e.target.files[0]);
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        setHasNewProfileImg(true);
        setProfileImgPreview(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <Layout>
      <div className="nft-authprofile-wrapper w-full">
        <div className="main-wrapper w-full">
          <div className="content-wrapper-profile-only w-full mb-6">
            <ProfileDescription />
            <Typography.Paragraph style={{ marginBottom: 12 }}>
              Your API Key
            </Typography.Paragraph>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 8,
              }}
            >
              <div
                style={{
                  backgroundColor: "#dddce0",
                  padding: "8px 12px",
                  borderRadius: 6,
                }}
              >
                <Typography.Text style={{ wordBreak: "break-all" }}>
                  {providerRegistry}
                </Typography.Text>
              </div>
              <CopyButton
                copied={copied}
                onSuccess={onCopy}
                text={providerRegistry}
              />
            </div>

            <Typography.Paragraph style={{ marginBottom: 8, marginTop: 16 }}>
              Direct Receive Status
            </Typography.Paragraph>
            <Switch checked={directPay} onChange={() => setShowModal(true)} />

            <Modal
              okButtonProps={{
                style: { backgroundColor: "#108ee9" },
              }}
              open={showModal}
              title="Confirm Change"
              confirmLoading={confirmLoading}
              onCancel={() => setShowModal(false)}
              onOk={handleToggleChange}
            >
              <p>
                Are you sure you want to change the direct receiving status?
              </p>
            </Modal>
          </div>
        </div>
      </div>
      <PersonalInfoTab
        profileImgPreview={profileImgPreview}
        profileImg={profileImg}
        browseProfileImg={browseProfileImg}
        profileImgInput={profileImgInput}
        profileImgChangHandler={profileImgChangHandler}
        hasNewProfileImg={hasNewProfileImg}
      />
    </Layout>
  );
}
